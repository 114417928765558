<template>
  <div class="page">
    <van-nav-bar title="反馈" left-arrow @click-left="onClickLeft">
      <template #right>
        <span @click="onRecrodClick">反馈记录</span>
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="form">
        <div>
          <div>
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="form.customCondition | enumMessage(getHomeCustomerConditionEnum)"
              label="客户情况"
              placeholder="请选择客户情况"
              @click="
                showSelect(form.customCondition, getHomeCustomerConditionEnum, 'customCondition')
              "
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="form.followStatus | enumMessage(getHomeFollowStatusEnum)"
              label="跟进结果"
              placeholder="请选择跟进结果"
              @click="
                showSelect(form.followStatus, getHomeFollowStatusEnum, 'followStatus')
              "
            />
            <van-field
              readonly
              clickable
              required
              :value="form.followDate"
              label="跟进时间"
              placeholder="请选择跟进时间"
              @click="showDateSelect(form.followDate, 'followDate')"
            />
            <van-field
              v-model.trim="form.followDetails"
              rows="3"
              maxlength="200"
              autosize
              label="跟进详情"
              type="textarea"
              required
              placeholder="请填写申请原因"
            />
            <div class="pictures">
              <div class="picture-section">
                <div class="title">附件信息</div>
                <div class="pictures">
                  <Uploader accept="image/*" v-model="form.attachList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <van-button type="primary" block @click="handleSubmit"
        >提交申请</van-button
      >
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="selectInfo.columns"
        :default-index="selectInfo.defaultIndex"
        value-key="displayName"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        @confirm="onDateConfirm"
        @cancel="showDatePicker = false"
        v-model="selectDateInfo.value"
        type="date"
        title="选择跟进时间"
      />
    </van-popup>
  </div>
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/caseHandle";
import { Toast } from "vant";
import dayjs from "dayjs";

const DEFAULT_FORM = {
  assignNo: "",
  customCondition: "",
  followDate: "",
  followDetails: "",
  followStatus: "",
  homeStatus: "",

  attachList: [],
};

export default {
  data() {
    return {
      form: {
        ..._.cloneDeep(DEFAULT_FORM),
      },

      showPicker: false,
      showDatePicker: false,

      selectInfo: {
        defaultIndex: 0,
        columns: [],
        key: "",
      },
      selectDateInfo: {
        value: null,
        key: "",
      },
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const loading = Toast.loading();
        const params = _.cloneDeep(this.form);
        if (!params.customCondition) {
          Toast(`请选择客户情况`);
          return;
        }
        if (!params.followStatus) {
          Toast(`请选择跟进结果`);
          return;
        }
        if (!params.followDate) {
          Toast(`请选择跟进时间`);
          return;
        }
        if (!params.followDetails) {
          Toast(`请输入跟进详情`);
          return;
        }

        Object.assign(params, {
          applyBizNo: this.$route.query.projectCode,
          orgName: this.$route.query.orgName,
          assignNo: this.$route.query.assignNo,
          homeStatus: this.$route.query.homeStatus,
        });
        var res = await Api.addFeedback({
          ...params,
        });

        loading.close();
        Toast.success(res.message);
        this.form = {
          ..._.cloneDeep(DEFAULT_FORM),
        };
      } catch (error) {
        Toast.fail(error);
      }
    },

    showSelect(value, enums, key) {
      const defaultIndex = Math.max(
        enums.map((e) => e.value).indexOf(value),
        0
      );
      this.selectInfo = {
        defaultIndex,
        columns: enums,
        key,
      };
      this.showPicker = true;
      this.key = key;
    },

    onConfirm(value) {
      const { selectInfo, form } = this;
      form[selectInfo.key] = value.value;
      this.showPicker = false;
    },

    showDateSelect(value, key) {
      this.selectDateInfo = {
        value: dayjs(value || dayjs().format("YYYY-MM-DD")).toDate(),
        key,
      };
      this.showDatePicker = true;
    },

    onDateConfirm(value) {
      const { selectDateInfo } = this;
      this.form[selectDateInfo.key] = dayjs(value).format("YYYY-MM-DD");
      this.showDatePicker = false;
    },

    onRecrodClick() {
      this.$router.push({
        name: "FeedBackRecord",
        query: this.$route.query,
      });
    },

    onSearch() {
      this.pageNo = 1;
      this.list = [];
      this.onLoad();
    },
    async onLoad() {
      try {
        this.finished = false;
        this.loading = true;
        const { pageNo, pageSize } = this;
        var res = await Api.findPageISO({
          pageNo,
          pageSize,
        });
        const { data } = res;
        const { list } = data;
        if (this.pageNo == 1) {
          this.list = list;
        } else {
          this.list = [...this.list, ...list];
        }
        this.finished = !data.isNextPage;
        this.pageNo++;
      } catch (error) {
        Toast.fail(error);
        this.list = [];
        this.finished = true;
      } finally {
        this.loading = false;
        this.refreshing = false;
      }
    },
    onRefresh() {
      this.pageNo = 1;
      this.onLoad();
    },
  },
  mounted() {
    this.fetchEnums("getHomeCustomerConditionEnum", "getHomeFollowStatusEnum");
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;

    .head {
      background: #fff;
      padding: 10px 10px;
      margin-bottom: 2px;
      font-size: 14px;
      font-weight: bold;
    }

    .form {
      margin-bottom: 10px;
    }
  }

  .actions {
    .van-button {
      border-radius: 0px;
    }
  }

  .pictures {
    margin: 5px 4px;
    background: #fff;
  }
  .picture-section {
    padding: 5px 10px;
    background: #fff;
    .title {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 10px;
    }

    /deep/ .van-uploader__upload {
      background: @pageBg;
    }
  }
}
</style>